<template>
  <div :is="expenseData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="expenseData === undefined">
      <h4 class="alert-heading">
        {{ $t("message.errorLoadingData") }}
      </h4>
      <div class="alert-body">
        {{ $t("message.expenses.expensesNotFound") }}
        <b-link class="alert-link" :to="{ name: 'apps-expense-list' }">
          {{ $t("message.expenses.expenseList") }}
        </b-link>
        {{ $t("message.expenses.forOtherExpense") }}
      </div>
    </b-alert>

    <template v-if="expenseData">
      <div>
        <b-card>
          <h2 class="mb-3">{{ $t("message.expenses.viewExpense") }}</h2>
          <b-row class="mb-2">
            <b-col md="8">
              <b-form-group label-for="paymentTypeExpenses">
                <template v-slot:label>
                  {{ $t("message.expenses.paymentMethod") }}
                </template>
                <v-select
                  v-model="expenseData.paymentType"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="type-status"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label-for="valueExpenses">
                <template v-slot:label>
                  {{ $t("message.expenses.value") }}
                </template>
                <b-form-input
                  v-model="expenseData.value"
                  v-money="getCurrencyFormat()"
                  class="mt-0"
                  label="value"
                  label-for="valueExpenses"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label-for="dateExpenses">
                <template v-slot:label>
                  {{ $t("message.bills.expiryDate") }}
                </template>

                <b-form-datepicker
                  id="created-date"
                  v-model="expenseData.date"
                  class="form-control"
                  :locale="$i18n.locale"
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col md="12" class="mb-0">
              <b-form-group label-for="descriptionExpenses">
                <template v-slot:label>
                  {{ $t("message.projects.description") }}
                </template>
                <b-alert variant="secondary" show>
                  <div class="alert-body">
                    <span v-html="expenseData.description"></span>
                  </div>
                </b-alert>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group l label-for="typeExpenses">
                <template v-slot:label>
                  {{ $t("message.tasks.type") }}
                </template>
                <v-select
                  v-model="expenseData.type"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="payment-status"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </div>

      <b-modal
        id="modal-delete-confirmation"
        modal-class="modal-danger"
        centered
        :title="$t('delete')"
        hide-footer
      >
        <b-card-text> {{ $t("message.deletAcount") }}</b-card-text>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <b-button
            v-b-modal.modal-danger
            class="mt-2"
            variant="danger"
            :to="{ name: 'apps-expense-list' }"
            @click="deleteExpense()"
          >
            {{ $t("message.buttons.delete") }}
          </b-button>
        </div>
      </b-modal>
    </template>
    <b-row>
      <b-col>
        <div class="text-right mr-2 mb-2 rButton">
          <b-button
            v-if="restrictions('button_edit_expense')"
            @click="
              $router.push({
                name: 'apps-expenses-edit',
                params: { id: expenseData.id },
              })
            "
            variant="primary"
          >
            {{ $t("message.buttons.edit") }}
          </b-button>
          <b-button
            v-if="restrictions('button_delete_expense')"
            v-b-modal.modal-delete-confirmation
            variant="outline-danger"
            class="ml-1"
          >
            {{ $t("message.buttons.delete") }}
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BButton,
  BCardText,
  BCardGroup,
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BFormDatepicker,
} from "bootstrap-vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VMoney } from "v-money";
import currency from "@/@core/utils/currency";
import AccessControl from "@core/utils/access-control";
import store from "@/store";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BButton,
    BCardText,
    BCardGroup,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormGroup,
    BFormInput,
    vSelect,
    flatPickr,
    BFormSelect,
    BFormTextarea,
    BFormDatepicker,
  },

  directives: {
    money: VMoney,
  },

  data() {
    return {
      userData: store.state.user.userData,
    };
  },

  setup() {
    const expenseData = ref(null);

    axios({
      method: "get",
      url: `${URL_API}expense/${router.currentRoute.params.id}`,
      headers: getHeader(store.state.user.userData),
    })
      .then((response) => {
        expenseData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          expenseData.value = undefined;
        }
      });

    return {
      expenseData,
    };
  },

  methods: {
    getCurrencyFormat() {
      return currency(this.$i18n.locale);
    },

    restrictions(value) {
      return AccessControl(value);
    },

    deleteExpense() {
      axios({
        method: "delete",
        url: `${URL_API}expense/${this.expenseData.id}`,
        headers: getHeader(this.userData),
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.deleted"),
              icon: "CheckIcon",
              variant: "success",
              text: `A despesa foi deletada com sucesso`,
            },
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            expenseData.value = undefined;
          }
        });
    },
  },
};
</script>

<style>
.fontTitle {
  font-size: 0.857rem;
}
</style>
